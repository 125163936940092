
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  name: "customer-financial-statements-networth",
  components: {},
  setup() {
    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    return { nFormat };
  },
  data() {
    let isLoading = true;
    const route = useRoute();
    const store = useStore();
    const cstmr_id = route.params.customer_id ?? null;
    const fin_id = route.params.fin_id ?? null;
    const customer = {
      birthdate: "",
      company_id: 0,
      detail: {},
      email: "",
      facilities: [],
      gender: "",
      media: [],
      name: "",
      nric: "",
      phone: "",
      package: "",
      net_worth_secured_liabilities: [],
      net_worth_secured_total: [],
      net_worth_unsecured_liabilities: [],
      net_worth_unsecured_total: [],
    };

    return {
      router: useRouter(),
      isLoading,
      store,
      fin_id,
      cstmr_id,
      customer,
      finDetails: null,
      isFinReady: false,
    };
  },
  async mounted() {
    setCurrentPageBreadcrumbs("Financial Statements HH", ["Customer"]);
    this.init();
  },
  methods: {
    init() {
      if (this.cstmr_id) {
        this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
          this.customer = this.store.getters.getCustomerData;
          this.isLoading = false;
        });
      }
    },
  },
});
